export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export const truncate = (str, n) => {
  return str ? str.length > n ? str.substring(0, n - 1) + "..." : str : '-' 
};

export const getUrlOfFile = (file, media) => {
  const urlImg = media ? 'image-default.png' : 'avatars/default.jpeg'
  try {
    return URL.createObjectURL(file);	 
  } catch (error) {
    return file || '/img/' + urlImg;	
  }
}

export const ConvertStringToHTML = function (str) {
  let parser = new DOMParser();
  let doc = parser.parseFromString(str, 'text/html');
  return doc.body;
};

export const getClassStatus = (data) => {
  let result = 'success';
  let status = data.toLowerCase()
  switch(status){
    case"active":
      result="success";
    break;
    case"publish":
      result="success";
    break;
    case"inactive":
      result="danger";
    break;
    case"draft":
      result="danger";
    break;
    case"reject":
      result="danger";
    break;
    case"deactive":
      result="danger";
    break;
    case"waiting review":
    case"waiting approval":
      result="warning";
    break
  }
  return result;
}

export const str_slug = (string) => {
  var slug = "";
  // Change to lower case
  var titleLower = string.toLowerCase();
  // Letter "e"
  slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
  // Letter "a"
  slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
  // Letter "o"
  slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
  // Letter "u"
  slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
  // Letter "d"
  slug = slug.replace(/đ/gi, 'd');
  // Trim the last whitespace
  slug = slug.replace(/\s*$/g, '');
  // Change whitespace to "-"
  slug = slug.replace(/\s+/g, '-');
  
  return slug;
}

export const getFilenameFromUrl = url => {
  return url.substring(url.lastIndexOf('/') + 1).split('?')[0]
}

export const checkValidationNumber = (event) => {
  if (event.target.value.length == 0 && event.which == 48 ){
    event.preventDefault();
  } else {
    if ((event.which < 48 || event.which > 57) && event.which !== 13){
        event.preventDefault();
    }
  }
}
