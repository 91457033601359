<template>
    <b-col md="12" class="mb-10" v-if="message">
        <b-alert show :variant="variant">
            {{message}}
        </b-alert>
    </b-col>
</template>
<script>
    export default {
        name: 'ResponseAlert',
        data() {
            return {
                variant: '',
                message: ''
            }
        },
        methods: {
            setResponse(message,variant){
                const _ = this;
                _.variant = variant;
                _.message = message;
                window.scrollTo(0,0);
                setTimeout(() => _.message='', 10000);
            },
        },
    };
</script>