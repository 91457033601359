<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="2" class="mb-2">
						<div class="image-input">
							<b-img :src="getUrlOfFile(models.image_profile)" class="mb-2" />
							<label for="file-input">
								<input id="file-input" type="file" accept="image/png, image/gif, image/jpeg, image/jpg"
									v-on:change="AddPhoto" hidden />
								<i class="fa fa-plus"></i> Upload Image
							</label>
						</div>
					</b-col>
					<b-col sm="5" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('first name')}" label="First Name">
							<b-form-input v-model="models.first_name" v-validate="'required'" data-vv-name="first name">
							</b-form-input>
							<div v-if="errors.has('first name')">
								<p class="text-danger">{{ errors.first('first name') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('last name')}" label="Last Name">
							<b-form-input v-model="models.last_name" v-validate="'required'" data-vv-name="last name">
							</b-form-input>
							<div v-if="errors.has('last name')">
								<p class="text-danger">{{ errors.first('last name') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('email')}" label="Email">
							<b-form-input type="email" v-model="models.email" v-validate="'required|email'"
								data-vv-name="email"></b-form-input>
							<div v-if="errors.has('email')">
								<p class="text-danger">{{ errors.first('email') }}</p>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="5" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('password')}" label="Password">
							<b-form-input type="password" v-model="models.password" v-validate="vars.role_password"
								data-vv-name="password"></b-form-input>
							<div v-if="errors.has('password')">
								<p class="text-danger">{{ errors.first('password') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('role')}" label="Role">
							<select v-model="models.role_id" v-validate="'required'" data-vv-name="role"
								class="form-control">
								<option value="">Select Role</option>
								<option v-for="(v,i) in masters.roles" :value="v.id" :key="i">
									{{v.name}}
								</option>
							</select>
							<div v-if="errors.has('role')">
								<p class="text-danger">{{ errors.first('role') }}</p>
							</div>
						</b-form-group>
						<b-form-group label="Status">
							<div class="col-sm-12 no-padding">
								<label class="radio-inline no-padding">
									<input type="radio" v-model="models.status" value="active">
									<span class="badge badge-success">Active</span>
								</label>
								<label class="radio-inline">
									<input type="radio" v-model="models.status" value="inactive">
									<span class="badge badge-danger">Inactive</span>
								</label>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
	</form>
</template>
<script>
	import api from '@/utils/api.js'
	import {
		getUrlOfFile
	} from '@/utils/helpers.js';
	import ResponseAlert from '@/components/response_alert';

	export default {
		name: 'FormAdmin',
		components: {
			ResponseAlert
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					first_name: "",
					last_name: "",
					email: "",
					role_id: "",
					image_profile: "",
					phone_number: '-',
					status: "active",
				},
				masters: {
					roles: [],
				},
				vars: {
					FormData: new FormData(),
					role_password: 'required|min:8'
				}
			}
		},
		created() {
			let _ = this
			_.getById();
			_.getRoles();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.vars.role_password = ''
					_.$axios.get(`${api.user}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.first_name = data.first_name;
							_.models.last_name = data.last_name;
							_.models.email = data.email;
							_.models.role_id = data.role_id;
							_.models.status = data.status;
							_.models.image_profile = data.image_profile;
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
			getRoles() {
				const _ = this
				_.$axios.get(api.role, {
					params: {
						status: 'active',
						orderby: 'name',
						sort: 'asc',
					}
				}).then(res => _.masters.roles = res.data.data.rows);
			},
			AddPhoto(event) {
				this.models.image_profile = event.target.files[0];
			},
			getUrlOfFile(file) {
				return getUrlOfFile(file);
			},
			save() {
				const _ = this;
				_.vars.FormData = new FormData();
				for (const i in _.models) {
					_.vars.FormData.append(i, _.models[i]);
				}
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						let message = "create"
						if (!_.$route.params.id) {
							request = _.$axios.post(api.user, _.vars.FormData);
						} else {
							message = "update"
							request = _.$axios.put(`${api.user}/${_.$route.params.id}`, _.vars.FormData);
						}
						request
							.then(() => {
								this.$showToast('Success', `Success ${message} admin`,)
								_.$router.push({
									name: _.config.uri
								})
							})
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		}
	}
</script>